export const courseDetailPropertyKey = {
  METHODOLOGIES: 'methodologies',
  SYLLABUS: 'syllabus',
  TEACHERS: 'teachers',
  SCHEDULES: 'schedules'
};

export const courseDetailPropertyKeys = [
  courseDetailPropertyKey.METHODOLOGIES,
  courseDetailPropertyKey.SYLLABUS,
  courseDetailPropertyKey.TEACHERS,
  courseDetailPropertyKey.SCHEDULES
];

export const newlyOpenedCourses = ['Junior'];
