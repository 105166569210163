import { SITE_URL } from '../apis/baseURL';
import langs from '../content/langs.json';

export * from './Newspaper';
export * from './courses';
export { default as website } from './website';

export const MONTH_NAME_FORMAT = 'MMM DD, yyyy';
export const MONTH_NAME_FORMAT2 = 'DD/MM/YYYY';

export const SORT_BY_ORDERING_ASC = 'ordering,asc';

export const contentType = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  BULLETS: 'BULLETS'
};

export const skill = {
  WRITING: 'Writing',
  READING: 'Reading',
  LISTENING: 'Listening',
  SPEAKING: 'Speaking'
};

// prefer export single params than whole objects
export const GRAPH_CMS_CDN = 'https://asset.dolenglish.vn';

export default {
  DEFAULT_LANG: langs[0],
  FACEBOOK_APP_ID: '722752771433071',
  // FACEBOOK_APP_ID: '1377624652358591', // ng-tv Cuong facebook app

  DOL_ENGLISH: {
    HOMEPAGE: SITE_URL,
    IELTS_REVIEW_RESULT: '/hall-of-fame',
    FACEBOOK_PAGE_ID: '391463584761881'
    // FACEBOOK_PAGE_ID: '100540292072721' // ng-tv Cuong facebook app
  },
  DOL_ONLINE: {
    HOMEPAGE: 'https://www.dolonline.com'
  },
  LOGO_WIDTH: 120,
  DESKTOP_MAX_WIDTH: 1440,
  DESKTOP_MENU_MAX_WIDTH: 1440,
  DESKTOP_HEADER_HEIGHT: 50,
  DESKTOP_HEADER_LOGO_MARGIN: 0,
  DESKTOP13INCHE_HEADER_LOGO_MARGIN: 50,
  DESKTOP_PRICING_WIDTH: 250,
  DESKTOP_STICKY_NAV_HEIGHT: 70,
  DESKTOP_STICKY_MAX_WIDTH: 850,

  MOBILE_HEADER_HEIGHT: 54,
  BREAKPOINT_MOBILE_STICKY_NAV_FULL_WIDTH: 600,

  BREAKPOINT_BURGER_MENU: 1280,
  GRAPH_CMS_CDN: 'https://asset.dolenglish.vn',
  REVIEWS_PER_PAGE: 10
};

export const testType = {
  TEXTSINGLE: 'TextSingle',
  TEXTMULTI: 'TextMulti',
  IMAGESINGLE: 'ImageSingle',
  IMAGEMULTI: 'ImageMulti',
  GAPFILL: 'GapFill'
};

export const ftQuestionTypes = {
  FILL_IN_THE_BLANK: 'FILL_IN_THE_BLANK',
  SELECTION: 'SELECTION',
  TFN: 'TFN'
};

export const ftTestTypes = {
  GRAMMAR: 'GRAMMAR',
  LISTENING: 'LISTENING',
  PRONUNCIATION: 'PRONUNCIATION',
  READING: 'READING',
  SPEAKING: 'SPEAKING',
  VOCAB: 'VOCAB',
  WRITING: 'WRITING'
};

export const ftTfn = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
  NOT_GIVEN: 'NOT_GIVEN'
};

export const env = {
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
};

export const dolCTAReferences = {
  HALL_OF_FAME: 'dol-ta-reference-hall-of-fame',
  REVIEW: 'dol-cta-reference-review',
  SCHEDULE: 'dol-cta-reference-calendar',
  REGISTER: 'form-register'
};

export const BLOG_TYPE = {
  DOL_NEWS: 'DOL_NEWS',
  STUDENT_STORY: 'STUDENT_STORY',
  VIEW_BLOG: 'VIEW_BLOG'
};
